import React from 'react'
import { graphql } from 'gatsby'
import Link from 'gatsby-link'
import Layout from '../layouts/Index'
import SEO from '../components/SEO'
import Books from '../components/Banners/Books'
import PreFooter from '../components/PreFooter'
import Style from '../styles/components/demo.module.scss'

class Demo extends React.Component {
  render() {
    const { 
      pageContext: {title},
      data: { demoPageContent, books, localPageInfos, staticText } 
    } = this.props;
    const staticPrismicTexts = staticText.edges[0].node.data.texts;
    const data = demoPageContent.edges[0].node.data;
    const videoId = data.video.embed_url && data.video.embed_url.split('?v=');
    const infos = localPageInfos.edges[0].node.context;
    
    return (
      <Layout 
        context={this.props.pageContext} 
        pageTitle={data.seo__meta_title_.text}
        _t={staticPrismicTexts}
      >
        <SEO
          pageTitle={data.seo__meta_title_.text}
          pageDescription={data.seo__meta_description_.text}
          context={this.props.pageContext}
        />
        <div className="page demo">
          <section className="regular">
            <div className="container">
              <div className="breadcrumb">
                <Link to="/">Wakatoon</Link>&nbsp;/&nbsp;
                <Link to={infos.breadcrumbParent.slug }>
                  {infos.breadcrumbParent.label}
                </Link>&nbsp;/&nbsp;
                {title}
              </div>
              <div className="page-head no-padding no-custom-style">
                <h2>{data.page_title.text}</h2>
              </div>

              { videoId && 
                <div>
                  <div className="yt-container">
                    <iframe
                      className="yt-video"
                      title={`Vidéo: ${data.page_title.text}`}
                      width="480"
                      height="270"
                      src={`https://www.youtube.com/embed/${videoId[1]}?feature=oembed`}
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen>
                    </iframe>
                  </div>
                </div>
              }

              <div
                style={{paddingTop:20}}
                className="justify"
                dangerouslySetInnerHTML={{ __html: data.content.html }}>
              </div>

              <div className={Style.books}>
                <div className={Style.subTitle} dangerouslySetInnerHTML={{ __html: data.page_subtitle.html }}></div>
                <Books 
                  {...books} 
                  shopUrl={this.props.pageContext.shop_urls[this.props.pageContext.langKey]} 
                  thumbnailsDisplay={true} 
                  context={this.props.pageContext} 
                  _t={staticPrismicTexts}
                />
              </div>
            </div>
          </section>
        </div>
        <PreFooter locale={this.props.pageContext.locale} />
      </Layout>
    );
  }
}

export default Demo;
export const pageQuery = graphql`
  query demoPage($locale: String!) {
    demoPageContent: allPrismicPageTest(filter: { lang: { eq: $locale} }) {
      edges {
        node {
          lang
          data {
            seo__meta_title_ {
              text
            }
            seo__meta_description_ {
              text
            }
            page_title {
              text
            }
            page_subtitle {
              html
            }
            video {
              embed_url
            }
            content {
              html
            }
          }
        }
      }
    }
    books: allPrismicBlockStoryslider(filter: { lang: { eq: $locale} }) {
      edges { ...books }
    }
    prefooter: allPrismicSectionPrefooter(filter: { lang: { eq: $locale} }) {
      edges {
        ...preFooter
      }
    }
    localPageInfos: allSitePage(
      filter: {
        context: {
          uid: {eq: "demo"},
          lang: { eq: $locale}
        }
    }) {
      edges {
        node {
          context {
            langKey
            breadcrumbParent {
              label
              slug
            }
          }
        }
      }
    }
    staticText: allPrismicGlobalStatictexts(filter: { lang: { eq: $locale} }) {
      edges { ...StaticTexts }
    }
  }
`;